<template>
    <div>
        <v-app-bar app dark flat fixed color="primary">
            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
            {{ title }}
            <v-spacer></v-spacer>
            <slot name="actions"></slot>
        </v-app-bar>

        <drawer v-model="drawer"></drawer>
    </div>
</template>

<script>
import Drawer from '@/components/admin/Drawer'
export default {
    props: { title: { type: String, default: 'Petu Power' } },
    data: () => ({ drawer: false }),
    components: { Drawer }
}
</script>