<template>
    <div>
        <v-navigation-drawer v-model="drawer" app temporary>
            <img src="@/assets/petu-home-logo.png" class="logo">

            <v-divider></v-divider>

            <v-list dense nav>
                <template v-for="(item, ix) in allowedItems">
                    <v-divider v-if="item.divider" :key="ix"></v-divider>
                    <v-subheader v-else-if="item.subheader" :key="ix">{{ item.subheader }}</v-subheader>
                    <v-list-item v-else :key="ix" :to="item.to" link>
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>


            <template v-slot:append>
                <v-list-item link class="item" to="/secure/session">
                    <v-list-item-icon>
                        <v-icon>mdi-qrcode-plus</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Comenzar sesión</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link dark dense class="item userItem d-flex justify-center">
                    <v-menu bottom max-width="auto" content-class="rounded-xl">
                        <template v-slot:activator="{ on, attrs }">
                                <v-list-item class="mx-0" v-bind="attrs" v-on="on">
                                <v-list-item-avatar>
                                    <v-img v-if="profilePic" :src="profilePic"></v-img>
                                    <v-avatar v-else color="primary" size="36">{{ initials }}</v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ customer.firstName }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ customer.email }}</v-list-item-subtitle>
                                </v-list-item-content>
                                </v-list-item>
                        </template>
                        <v-card >
                            <v-list-item-content class="justify-center pb-0">
                                <div class="mx-auto text-center">
                                    <v-avatar class="white--text mb-2 petu-dark-green-bg">
                                        <h3 v-if="!profilePic">{{ initials }}</h3>
                                        <v-img v-else :src="profilePic"></v-img>
                                    </v-avatar>
                                    <h4>{{ customer.firstName }}</h4>
                                    <p class="text-caption">{{ customer.email }}</p>
                                    <v-divider></v-divider>
                                    <v-btn to="/" class="no-uppercase" tile block depressed text>Página</v-btn>
                                    <v-divider></v-divider>
                                    <v-btn to="/perfil" class="no-uppercase" tile block depressed text>Perfil</v-btn>
                                    <v-divider></v-divider>
                                    <v-btn @click="logout" class="no-uppercase" tile block depressed text>Salir</v-btn>
                                </div>
                            </v-list-item-content>
                        </v-card>
                    </v-menu>
                </v-list-item>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { _st } from '@/softech';
import { PetuCustomer } from '@/classes/customer';

export default {
    props: {
        value   : { type: Boolean, default: false },
    },
    data: () => ({
        drawer: false,
        isPetuPunchAdmin: false,
        items: [
            { title: 'Dashboard', to: '/secure/dashboard', icon: 'mdi-view-dashboard' },
            { title: 'Órdenes', to: '/secure/orders', icon: 'mdi-cash-multiple' },
            { title: 'Productos', to: '/secure/products', icon: 'mdi-store-outline' },
            { title: 'Usuarios', to: '/secure/customers', icon: 'mdi-account-multiple' },
            { title: 'Cupones', to: '/secure/coupons', icon: 'mdi-ticket-percent-outline' },
            { title: 'Sesiones', to: '/secure/sessions', icon: 'mdi-format-list-numbered'},
            { title: 'Mensajes', to: '/secure/messages', icon: 'mdi-message-outline'},
            { title: 'Evidencias', to: '/secure/evidence', icon: 'mdi-receipt'},
            { title: 'Ponches', to: '/secure/timestamps', icon: 'mdi-timetable', isPetuPunchAdmin: true },
            { title: 'Petu Punch', to: '/secure/petu-punch', icon: 'mdi-clock-time-three', isPetuPunchAdmin: true },
            { title: 'Reporting', to: '/secure/reporting', icon: 'mdi-circle-slice-1' },
            { divider: true },
            { title: 'Crear Órdenes ATH Móvil', to: '/secure/ath-movil', icon: 'mdi-credit-card-outline' }
        ],
    }),
    async mounted() {
        this.drawer = this.value
        this.isPetuPunchAdmin = await this.$store.getters['auth/isPetuPunchAdmin']
    },
    methods: {
        ...mapActions({ logout: 'auth/logout' })
    },
    computed: {
        ...mapGetters({ customer: 'auth/customer', isAdmin: 'auth/isAdmin', profilePic: 'auth/profilePic' }),
        initials: ({ customer }) => customer ? (new PetuCustomer(customer)).getInitials() : '',
        allowedItems() {
            return this.items.filter((i) => !i.isPetuPunchAdmin || (i.isPetuPunchAdmin === this.isPetuPunchAdmin))
        }
    },
    watch: {
        value(v) { this.drawer = v },
        drawer(v) { this.$emit('input', v)}
    }
}
</script>

<style lang="scss" scoped>
.logo {
    width: 200px;
    padding: 5px 10px 0 10px;
}
.item {
    margin: 0 5px 5px 5px;
    border-radius: 6px;
}
.item::before {
    border-radius: 6px;
}
.userItem {
    background-color: #639d43;
}
</style>